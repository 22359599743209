import React from "react";
import ReactPlayer from "react-player";

function VideoFirst() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactPlayer
        url="/Header-Banner-Updated-1.mp4" // Relative path to the video file in the public folder
        playing={true}
        loop={true}
        muted={true}
        controls={false} // Set controls to false to hide the entire controls bar
        config={{
          file: {
            attributes: {
              crossorigin: "anonymous",
            },
          },
        }}
        width="100%"
        height="100%"
        style={{ objectFit: "cover" }}
      />
    </div>
  );
}

export default VideoFirst;
