import React, { useState } from 'react';
import { Row, Col, Button, Form, Image, Container } from 'react-bootstrap';
import { Parser } from 'html-to-react';


import img2 from "../../assets/images/card2.png";

const Read = ({Cards}) => {
    const [Card, setCard] = useState(JSON.parse(localStorage.getItem('CardData')) || Cards);
    const tags = Card['Tags']?JSON.parse(Card['Tags']): [
        'Green Hydrogen',
        'Green Ammonia',
        'National Hydrogen Policy',
        'Renewable Energy',
        'Solar Energy',
        'Zero Emission',
        'Energy Transition',
        'Decarbonization',
        'Renewable Purchase Obligation',
        'Narendra Modi',
        'COP Summit',
    ];
    

    const htmlContent = Card ? Card.Body : "";

    const parser = new Parser();
    const jsxContent = parser.parse(htmlContent);

    return (
        <>
            <section className='py-5' >
                <div style={{ padding: "10px" }}>
                    <Row className="justify-content-center" style={{ display: "flex", gap: "7px" }}>
                        <Col lg={8} style={{ padding: '30px', border: '1px solid grey' }}>
                            <div className="mb-4">
                                <Button style={{ border: '2px solid #a20000', backgroundColor: '#a20000', color: 'white', padding: '8px', fontSize: 'large' }}>{Card ? Card.Header : ""}</Button>
                            </div>
                            <Image src={Card ? Card.ImageURL : ""} alt="" fluid className="mb-4" />
                            <>
                                {jsxContent}
                            </>
                            <Container>
                                <h1 className="text-left mb-4">Tags</h1>
                                <Row xs={1} sm={2} md={3}>
                                    {tags.map((tag, index) => (
                                        <Col key={index} className="mb-3">
                                            <Button className="tag" style={{ backgroundColor: '#a20000', margin: '5px', width: '100%', height: '40px', padding: '0 15px', lineHeight: '40px' }}>{`${tag}`}</Button>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>

                        </Col>
                        <Col lg={3} style={{ padding: '30px', border: '1px solid grey', maxWidth: "100%", height: "100%" }}>
                            <div className="mb-4">
                                <Form.Control type="text" placeholder="Search.." style={{ padding: '8px', fontSize: 'large' }} />
                            </div>
                            <h1 className="mb-4">Recent Posts</h1>
                            <p>How Solar Installations Can Benefit Real Estate Developers</p>
                            <p>Solar Construction: Need Of The Hour</p>

                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default Read;


