import React,{useEffect} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import "../blog/Blog.css";
import csr1 from "../../assets/images/csr1.jpg";
import csr2 from "../../assets/images/csr2.jpg";
import csr3 from "../../assets/images/csr3.jpg";
import csr4 from "../../assets/images/csr4.jpg";
import csr5 from "../../assets/images/csr5.jpg";
import csr6 from "../../assets/images/csr6.jpg";
import csr7 from "../../assets/images/csr7.jpg";
// Import the background image
import backgroundImage from "../../assets/images/slide2.jpeg";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from 'aos';

const CSR = () => {
    useEffect(() => {
        AOS.init(); // Initialize AOS
      }, []);
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no" style={{ overflowX: 'hidden' }}>
    <Row className="elementor-row">
        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
            <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                    <div className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title" data-id="b12b60d" data-element_type="widget" data-widget_type="modina_section_title.default">
                        <div className="elementor-widget-container">
                            <div className="block-contents text-center">
                                <div className="section-title">
                                    <h5 className="text-center"></h5>
                                    <span></span>
                                    <h2 style={{fontSize:"55px"}}>
                                        Corporate Social Responsibility</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    </Row>
</Container>
            </section>
            <Container className="my-5 px-5 text-end">
                <Row className="elementor-row">
                    {/* Card Section 1 */}
                    <Col md={12} className="elementor-column elementor-col-50" data-aos="fade-up">
                        <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                            <Row className="g-0">
                                <Col md={5}>
                                    <Card.Img
                                        variant="top"
                                        src={csr1}
                                        style={{ width: "100%", height: "auto" }}
                                    />
                                </Col>
                                <Col md={7}>
                                    <Card.Body className='text-center' >
                                        <Card.Title style={{ color: "black",textAlign: "left" }}>Sponsored Sports Teams</Card.Title>
                                        <Card.Text style={{ textAlign: "left", lineHeight: "1.5",fontSize:"15px" }}>
                                            Gautam Solar has been a proponent for active participation in sports. We sponsored
                                        
                                            the Indian Team at the IKF Korfball World Championship 2023 held in Taiwan. We
                                          
                                            are also a sponsor for Hans Women Football Club playing in the Football Delhi
                                      
                                            Women’s Premier League.
                                        </Card.Text>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>




                </Row>
            </Container>
            <Container className="my-5 px-5 text-end">
                {/* Card Section 2 */}
                <Col md={12} className="elementor-column elementor-col-50 ">
                    <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                        <Row className="g-0">
                            <Col md={7}>
                                <Card.Body>
                                    <Card.Title style={{ color: "black",textAlign: "right" }}>Conducted Health Checkup Camps</Card.Title>
                                    <Card.Text style={{ textAlign: "right", lineHeight: "1.5",fontSize:"15px" }}>
                                        Gautam Solar has partnered with NABL accredited ICMR Approved Dr. Chopra’s
                              
                                        Path Clinic in Ghaziabad to conduct 18 free preventive health checkup camps in
                             
                                        the city. A number of locations have been covered so far, including Gulmohar
                                    
                                        Enclave, Ajnara Integrity, Daulatpura, New Panchwati Colony, Gargi Hospital,
                              
                                        Chhapraula and Lions Eye Hospital, benefiting more than 1500 people.
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                            <Col md={5}>
                                <Card.Img
                                    variant="top"
                                    src={csr2}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Container>
            <Container className="my-5 px-5 text-end">
                {/* Card Section 2 */}
                <Col md={12} className="elementor-column elementor-col-50 "  data-aos="fade-up">
                    <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                        <Row className="g-0">
                            <Col md={5}>
                                <Card.Img
                                    variant="top"
                                    src={csr3}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Col>
                            <Col md={7}>
                                <Card.Body>
                                    <Card.Title style={{ color: "black",textAlign: "left" }}>Donated Medical Equipments to AIIMS Delhi</Card.Title>
                                    <Card.Text style={{ textAlign: "left", lineHeight: "1.5",fontSize:"15px" }}>
                                        Gautam Solar donated multiple medical equipments, including shower trolley and
                                     
                                        shower panels to the Department of Plastic, Reconstructive & Burns Surgery at All
                                 
                                         India Institute of Medical Science (AIIMS) Delhi for better medical treatment and
                        
                                         care for patients with burn injuries.
                                    </Card.Text>
                                </Card.Body>
                            </Col>

                        </Row>
                    </Card>
                </Col>

            </Container>
            <Container className="my-5 px-5 text-end">
                {/* Card Section 2 */}
                <Col md={12} className="elementor-column elementor-col-50 " data-aos="fade-up">
                    <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                        <Row className="g-0">
                            <Col md={7}>
                                <Card.Body>
                                    <Card.Title style={{ color: "black",textAlign: "right" }}>#FightPollutionWithGautam</Card.Title>
                                    <Card.Text style={{ textAlign: "right", lineHeight: "1.5",fontSize:"15px" }}>
                                    Gautam Solar raised awareness about the pollution crisis in Delhi and measures to
                                  
                                    reduce their carbon footprints in order to tackle the problem, through an auto-
                               
                                    rickshaw awareness and outreach program and encouraged people to share their
                              
                                    ideas using the hashtag #FightPollutionWithGautam.


                                    </Card.Text>
                                </Card.Body>
                            </Col>
                            <Col md={5}>
                                <Card.Img
                                    variant="top"
                                    src={csr4}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Container>
            <Container className="my-5 px-5 text-end">
                {/* Card Section 2 */}
                <Col md={12} className="elementor-column elementor-col-50 " data-aos="fade-up">
                    <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                        <Row className="g-0">
                            <Col md={5}>
                                <Card.Img
                                    variant="top"
                                    src={csr5}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Col>
                            <Col md={7}>
                                <Card.Body>
                                    <Card.Title style={{ color: "black",textAlign: "left" }}>Contributed to Active Library Program</Card.Title>
                                    <Card.Text style={{ textAlign: "left", lineHeight: "1.5",fontSize:"15px" }}>
                                    Gautam Solar made a generous financial contribution to the Kutumb Foundation
                                 
                                    for Qissagadh Active Library Program. This program caters to the empowerment
                               
                                    of lesser privileged youth of all ages through independent research, reading and
                              
                                    collaborative learning.
                                    </Card.Text>
                                </Card.Body>
                            </Col>

                        </Row>
                    </Card>
                </Col>

            </Container>
            <Container className="my-5 px-5 text-end">
                {/* Card Section 2 */}
                <Col md={12} className="elementor-column elementor-col-50 " data-aos="fade-up">
                    <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                        <Row className="g-0">
                            <Col md={7}>
                                <Card.Body>
                                    <Card.Title style={{ color: "black", textAlign: "right" }}>Solar Plant Installation in Haridwar</Card.Title>
                                    <Card.Text style={{ textAlign: "right", lineHeight: "1.5",fontSize:"15px" }}>
                                        Gautam Solar supplied solar modules and installed a solar power plant at Har Ki
                                     
                                        Pauri in the holy town of Haridwar. The Plant was commissioned by the local NGO
                                      
                                        Sri Ganga Sabha.
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                            <Col md={5}>
                                <Card.Img
                                    variant="top"
                                    src={csr6}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>

            </Container>
            <Container className="my-5 px-5 text-end">
                {/* Card Section 2 */}
                <Col md={12} className="elementor-column elementor-col-50 " data-aos="fade-up">
                    <Card className="elementor-widget elementor-widget-image text-center h-100" style={{ border: '1px solid grey', padding: '15px',maxWidth:"100%" }}>
                        <Row className="g-0">
                        <Col md={5}>
                                <Card.Img
                                    variant="top"
                                    src={csr7}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            </Col>
                            <Col md={7}>
                                <Card.Body >
                                    <Card.Title style={{ color: "black",textAlign: "left" }}>Vaccination Awareness Campaign in Rajasthan</Card.Title>
                                    <Card.Text style={{ textAlign: "left", lineHeight: "1.5",fontSize:"15px" }}>
                                    Gautam Solar launched a public awareness campaign to advocate for COVID-19
                                    
                                    vaccination through the medium of local hindi newspapers in the areas of Jaipur,
                                  
                                    Jodhpur, Udaipur, Ajmer, Bikaner, Alwar, Kota, Chittorgarh, Gangapur, Nagaur, Pali,
                                
                                    Bhilwara, Barmer, Sikar, Sri Ganganagar and Banswara in the state of Rajasthan.
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                           
                        </Row>
                    </Card>
                </Col>

            </Container>








        </>
    );
};

export default CSR;
