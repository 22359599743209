import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "../MonoPerc/MonoPerc.css";
import img1 from "../../assets/images/monop1 (2).png";
import img2 from "../../assets/images/monop2 (1).png";
import img3 from "../../assets/images/mono1.png";
import img4 from "../../assets/images/mono2.png";
import img7 from "../../assets/images/mono3.png";
import img9 from "../../assets/images/View-2.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaDownload } from "react-icons/fa";
import { HiArrowNarrowRight } from "react-icons/hi"
import { Link as RouterLink } from "react-router-dom";


import backgroundImage from "../../assets/images/slide2.jpeg";



const Slide = ({ src }) => (
    <div>
        <img src={src} alt="Slide" />
    </div>
);

const MonoPerc = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerPadding: "10%",
        nextArrow: (
          <div className="slick-arrow slick-next">

          </div>
        ),
        prevArrow: (
          <div className="slick-arrow slick-prev">
          
          </div>
        ),
      };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };
    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2>Mono PERC Solar Modules</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Enquiry Now */}
            <Container className="py-5" lg={12} >
                <Row >
                    <Col md={6} className="text-left" style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey", marginBlock: "auto" }}>
                        <p>
                            Gautam Solar’s Mono PERC Solar Modules utilize fully automatic setup and latest machines during the manufacturing and quality testing process to ensure solar modules with high power, efficiency, reliability, durability and strength.
                        </p>
                        <RouterLink to="/contactus" style={{ textDecoration: "none" }}>
                            <Button  style={{ color: "#a20000", backgroundColor: "white" }}>Enquiry Now <HiArrowNarrowRight /></Button>
                        </RouterLink>
                    </Col>
                    <Col md={6}>
                        <Slider {...settings}>
                            <Slide src={img2} />
                            <Slide src={img9} />
                            <Slide src={img1} />
                        </Slider>
                    </Col>
                </Row>
            </Container>

            <section className="py-5 col-lg-12" style={{ backgroundColor: "#f5f5f5" }}>
                <Container>
                    <div className="box">
                        <Row>
                            <Col md={6}>
                                <Image
                                    src={img3}
                                    alt="Efficiency"
                                    fluid
                                    className="img-fluid hover-effect"
                                    style={{ maxWidth: "100%", margin: "0 auto", display: "block" }} // Ensure image is responsive and centered
                                />
                            </Col>
                            <Col md={6} style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey", marginBlock: "auto" }}>
                                <h1 style={{ color: "black" }}>Round Ribbon for Lower Shading on Cells</h1>
                                <p>
                                    Featuring Round Ribbon Connectors of diameter 0.35 mm (as opposed to conventionally used flat ribbon of width 0.9 mm), Gautam Solar’s Mono PERC Solar Modules provide up to 2.5% more power due to lower shaded area on the solar cells.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className="py-5">
                <Container>
                    <Row className="d-flex align-items-center">
                        <Col md={6} style={{ padding: "30px", fontSize: "20px", fontFamily: "serif", color: "grey" }}>
                            <h1 style={{ color: "black" }}>Non-Destructive Cell Cutting for Lower Chances of Micro-cracks</h1>
                            <p>
                                Gautam Solar’s Mono PERC Solar Modules incorporate Half-Cut Cells produced using Non-Destructive Cell Cutting (NDC). In this process, low-temperature laser with temperature less than 130°C is used. This helps lower the chances of micro-cracks in the solar modules.
                            </p>
                        </Col>
                        <Col md={6}>
                            <Image className="img-fluid hover-effect" src={img4} alt="N-type TOPCon Structure" fluid />
                        </Col>
                    </Row>
                </Container>
            </section>





            <section className="py-5" id="tigerneo2">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} sm={4}>
                            <div className="fl text-right">
                                <div className="list">
                                    <ul>
                                        <li>Bigger M10 Mono PERC Solar Cells for higher wattage <i style={{ width: "40px" }}>01</i></li>
                                        <li>144/120 Half-Cut Cells for better performance in low light <i>02</i></li>
                                        <li>Multi-Busbar Structure for Lower Electrical Losses <i style={{ width: "35px" }}>03</i></li>
                                        <li>Non-Destructive Cell Cutting Process for lower chances of micro-cracks <i style={{ width: "50px" }}>04</i></li>
                                        <li>Round Ribbon Connectors for better utilization of light <i>05</i></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className="pic text-center">
                                <img className="img-fluid hover-effect" src={img7} alt="TigerNeo" />
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>
                            <div className="fr text-left">
                                <div className="list">
                                    <ul>
                                        <li><i>06</i>Versatile modules ideal for Utility/C&I/Rooftop Installations</li>
                                        <li><i style={{ width: "35px" }}>07</i>Available in Monofacial and Bifacial</li>
                                        <li><i>08</i>All Black and Silver frame available for premium aesthetics</li>
                                        <li><i style={{ width: "35px" }}>09</i>Resilient to extreme weather conditions</li>
                                        <li><i style={{ width: "35px" }}>10</i>Excellent Anti-PID Performance</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container style={{ padding: "20px" }}>
                <section>
                    <Row className="text-center">
                        <h1 style={{ color: "black", fontSize: "40px", padding: "30px" }}>Datasheet</h1>
                    </Row>
                    <Row style={{ display: "flex", padding: "20px" }}>
                        <Col className="col-lg-3 d-flex align-items-center justify-content-center" style={{ boxSizing: "border-box", border: "2px solid black",marginRight: "10px" }}>
                            <h1>Utility Ground-mounted & Rooftop</h1>
                        </Col>
                        <Col className="col-lg-8" style={{ boxSizing: "border-box", border: "2px solid black" }}>
                            <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                                <a href="/540 Wp - 560 Wp Monofacial.pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                                    <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                                    540 Wp - 560 Wp Monofacial (144 Cells)
                                </a>
                            </h5>
                            <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                                <a href="/540 Wp - 560 Wp Bifacial (144 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                                    <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                                    540 Wp - 560 Wp Bifacial (144 Cells)
                                </a>
                            </h5>
                            <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                                <a href="/530 Wp - 545 Wp DCR Monofacial (144 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                                    <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                                    530 Wp - 545 Wp DCR Monofacial (144 Cells)
                                </a>
                            </h5>
                        </Col>
                    </Row>
                    <Row style={{ display: "flex", padding: "20px" }}>
                        <Col className="col-lg-3 d-flex align-items-center justify-content-center" style={{ boxSizing: "border-box", border: "2px solid black", alignItems: "center", fontSize: "20px",marginRight: "10px" }}>
                            <h1>Rooftop</h1>
                        </Col>
                        <Col className="col-lg-8" style={{ boxSizing: "border-box", border: "2px solid black" }}>
                            <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                                <a href="/440 Wp - 460 Wp Monofacial & Bifacial (120 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                                    <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                                    440 Wp - 460 Wp Monofacial & Bifacial (120 Cells)
                                </a>
                            </h5>
                            <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                                <a href="/440 Wp - 460 Wp All Black (120 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                                    <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                                    440 Wp - 460 Wp All Black (120 Cells)
                                </a>
                            </h5>
                            <h5 style={{ display: "flex", alignItems: "center", color: "#a20000" }}>
                                <a href="/400 Wp - 415 Wp All Black (108 Cells).pdf" download style={{ textDecoration: "none", color: "#a20000" }}>
                                    <FaDownload style={{ marginRight: "10px", cursor: "pointer" }} />
                                    400 Wp - 415 Wp All Black (108 Cells)
                                </a>
                            </h5>
                        </Col>
                    </Row>
                </section>
            </Container>

        </>


    );
};

export default MonoPerc;
