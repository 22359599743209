import React, { useEffect } from "react";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import Solar from "../../assets/images/gautam.jpg";
import solarproduct from "../../assets/images/solar-product2.webp";
import solarproductplus from "../../assets/images/new-launch-solar-2.webp";
import "./SolarMod.css";
import "aos/dist/aos.css"; // Import AOS styles
import AOS from 'aos';
import { Link as RouterLink } from "react-router-dom";

function SolarCard({ imgSrc, title, description, link1, link2 }) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="text-center card-container">
      <Card>
        <Card.Img variant="top" src={imgSrc} className="img-fluid justify-content-center " alt={title} style={{ maxWidth: '100%', height: 'auto', }}   />

        <Card.Body className="d-flex flex-column">
          <Card.Title style={{ color: "#a20000" }}>{title}</Card.Title>
          <Card.Text style={{ textAlign: "left", lineHeight: "1.5", marginLeft: "15px" }}>
            {description}
          </Card.Text>
          <div className="mt-auto text-center">
            <RouterLink to={link1} style={{ textDecoration: "none" }} onClick={handleClick}>
              <Button variant="primary" style={{ backgroundColor: "#a20000", marginRight: "10px" }}>
                Read More &#8594;
              </Button>
            </RouterLink>
            <RouterLink to={link2} style={{ textDecoration: "none" }} onClick={handleClick}>
              <Button variant="primary" style={{ backgroundColor: "#a20000" }}>
                Enquire Now &#8594;
              </Button>
            </RouterLink>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

function SolarMod() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <>
      <section className="py-5 text-center container">
        <div className="row py-4" data-aos="fade-down-right">
          <div className="col-lg-7 col-md-8 mx-auto text-center">
            <h2 className="fw-bold" style={{ position: 'relative', color: "#a20000" }}>
              <span style={{
                position: 'absolute',
                top: '-15px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderBottom: '2px solid #faab04',
                width: '50px',
              }}></span>
              Solar Modules
            </h2>
          </div>
        </div>

        <Container className=" px-3">
          <Row className="d-flex justify-content-lg-end">
            {/* N-Type TOPCon Solar Modules */}
            <Col lg={6} md={12} className="mb-4">
              <SolarCard
                imgSrc={solarproductplus}
                title="N-Type TOPCon Solar Modules"
                description={(
                  <>
                    Gautam Solar’s N-Type TOPCon Solar Modules offer 5% more power in the same area, thereby providing 3.26% BOS savings and 1.56% LCOE reduction. Efficiency up to 22.65%. Available in <br />
                    • 565-585 Wp (Monofacial) <br />
                    • 580-585 Wp (Bifacial Glass-to-Glass) <br />
                    • 580-585 Wp (Bifacial Transparent Backsheet)
                  </>
                )}
                link1="/topcon"
                link2="/contactbox"
              />
            </Col>

            {/* Mono PERC Solar Modules */}
            <Col lg={6} md={12} className="mb-4">
              <SolarCard
                imgSrc={solarproduct}
                title="Mono PERC Solar Modules"
                description={(
                  <>
                    Gautam Solar’s Mono PERC Solar Modules incorporate the latest module technologies including M10 Half-Cut Cells and MBB Design with Round Ribbon Connectors, produced using NDC. Efficiency up to 21.68%. Available in: <br />
                    • 440-460 Wp | 530-560 Wp (Monofacial) <br />
                    • 440-460 Wp | 540-560 Wp (Bifacial Transparent Backsheet) <br />
                    • 400-415 Wp | 440-460 Wp (All Black)
                  </>
                )}
                link1="/mono"
                link2="/contactbox"
              />
            </Col>
          </Row>
        </Container>

        <Container className="text-center">
          <div className="mb-5" style={{ marginTop: '20px' }}>
            <img src={Solar} alt="get" className="img-fluid" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        </Container>
      </section>
    </>
  );
}

export default SolarMod;
