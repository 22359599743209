import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import factorie1 from "../../assets/images/factory1.webp";
import factorie2 from "../../assets/images/factory2.webp";
import factorie3 from "../../assets/images/factory3.webp";
import factorie4 from "../../assets/images/factory4.webp";

const Factories = () => {
  return (
    <section className="py-4 text-center container">
      <div className="row ">
        <div className="col-lg-9 col-md-8 mx-auto text-center">
          <h2 className="fw-bold" style={{ position: 'relative', color: "black" }}>
            <span style={{
              position: 'absolute',
              top: '-15px',
              left: '50%',
              transform: 'translateX(-50%)',
              borderBottom: '2px solid #faab04',
              width: '50px',
            }}></span>
            Our <span style={{ fontWeight: 'bold', color: '#a20000' }}> Factories  </span>
          </h2>
        </div>
      </div>
      <Container className="my-5 px-3">
        <Row className="elementor-row">
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factorie1}
                className="img-fluid"
                alt="Factory 1"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Factory 1</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Plot-67 & 68, Sec-8A, SIDCUL, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factorie2}
                className="img-fluid"
                alt="Factory 2"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Factory 2</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Plot-69 & 70, Sec-8A, SIDCUL, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container className="my-5 px-3">
        <Row className="elementor-row">
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factorie3}
                className="img-fluid"
                alt="Factory 3"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Factory 3</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  Plot-114 - 115, Sec-6A, SIDCUL, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} className="elementor-column elementor-col-12 mb-4">
            <Card className="elementor-widget elementor-widget-image text-center h-100">
              <Card.Img
                variant="top"
                src={factorie4}
                className="img-fluid"
                alt="Factory 4"
              />
              <Card.Body>
                <Card.Title style={{ color: "black" }}>Factory 4</Card.Title>
                <Card.Text style={{ textAlign: "center", lineHeight: "1.5" }}>
                  A-2, Vardhaman Industrial Area, Haridwar, Uttarakhand, INDIA
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Factories;
